import { Layout } from 'antd';
import { Component } from 'react';
import { RedocStandalone } from 'redoc';
import Styled from './App.style';
import Topbar from './components/topbar';

const { Content } = Layout;

class App extends Component {
    state = {
        activeTabKey: 'intro'
    };

    redocOptions = {
        hideDownloadButton: true,
        // disableSearch: true,
        hideSchemaTitles: true,
        jsonSampleExpandLevel: 'all',
        pathInMiddlePanel: true
    };

    setActiveTabKey = (key) => {
        setTimeout(() => {
            
            this.setState({
                activeTabKey: key
            });

        }, 250);
    };

    getSpecUrlByKey = () => {
        const {
            activeTabKey,
        } = this.state;

        switch (activeTabKey) {
            case 'intro':
            default:
                return '/specs/bukku-intro.yaml';
            case 'sales':
                return '/specs/bukku-sales-api.yaml';
            case 'purchase':
                return '/specs/bukku-purchase-api.yaml';
            case 'product':
                return '/specs/bukku-product-api.yaml';
            case 'contact':
                return '/specs/bukku-contacts-api.yaml';
            case 'account':
                return '/specs/bukku-account-api.yaml';
            case 'lists':
                return '/specs/bukku-lists-v2-api.yaml';
            case 'files':
                return '/specs/bukku-files-api.yaml';
        }
    };

    render() {
        const {
            activeTabKey,
        } = this.state;

        return (
            <Styled>
                <Layout>
                    <Topbar activeTabKey={activeTabKey} setActiveTabKey={this.setActiveTabKey} />
                    <Content>
                        <RedocStandalone
                            options={this.redocOptions}
                            specUrl={this.getSpecUrlByKey()}
                        />
                    </Content>
                </Layout>
            </Styled>
        );
    }
}
export default App;

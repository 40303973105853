import { Component } from 'react';
import { Layout, Tabs } from 'antd';

const { Header } = Layout;
const { TabPane } = Tabs;

class Topbar extends Component {
    render() {
        const {
            activeTabKey,
            setActiveTabKey,
        } = this.props;

        return <Header>
            <Tabs defaultActiveKey={activeTabKey} onChange={setActiveTabKey}>
                <TabPane tab="Introduction" key="intro" />
                <TabPane tab="Sales" key="sales" />
                <TabPane tab="Purchase" key="purchase"/>
                <TabPane tab="Contact" key="contact" />
                <TabPane tab="Product" key="product" />
                <TabPane tab="Account" key="account" />
                <TabPane tab="Lists" key="lists" />
                <TabPane tab="Files" key="files" />
            </Tabs>
        </Header>;
    }
}
export default Topbar;

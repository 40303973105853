import styled from 'styled-components';

export default styled.div`
    .ant-layout {
        height: calc(100vh);

        .ant-layout-header {
            background: #ffffff;
            padding: 0 16px;
            line-height: 48px;
            height: 48px;
        }

        .ant-layout-content {
            overflow: auto;
        }
    }
`;
